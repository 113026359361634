import PromotionItem from './PromotionItem/promotionItem';
import { PromotionContainerStyle, PromotionContainerH2 } from './promotionContainer.module.css';
import { PromotionTypeWrapper } from '../../types/types';
import H2 from '../Blocks/H2/H2';
import { vkPixelPageView } from '../../Utils/vkPixel';
import React, { useEffect } from 'react';
import { CircularProgress } from '@material-ui/core';

interface PromotionContainerProps {
  promotions: PromotionTypeWrapper;
}

const PromotionContainer = ({ promotions }: PromotionContainerProps) => {
  useEffect(() => {
    vkPixelPageView();
  }, []);

  return (
    <div className={PromotionContainerStyle}>
      <H2 className={PromotionContainerH2}>Акции</H2>
      {!promotions && <CircularProgress />}
      {promotions && promotions.Promo.map((promo) => (
        <PromotionItem
          key={promo.Heading}
          id={promo.id}
          promoConditions={promo.Conditions}
          promoDescription={promo.Description}
          promoName={promo.Heading}
        />
      ))}
    </div>
  );
};

export default PromotionContainer;
