import { PromotionConditionType } from '../../../types/types';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

const useStyles = makeStyles({
  root: {
    maxWidth: '800px',
  },
  media: {
    height: 140,
  },
  paraPreWrap: {
    whiteSpace: 'pre-wrap',
  },
});

interface PromotionItemProps {
  promoName: string;
  promoConditions: PromotionConditionType[];
  promoDescription: string;
  id: number;
}

const PromotionItem = ({
  promoName, promoConditions, promoDescription, id,
}: PromotionItemProps) => {
  const classes = useStyles();

  return (
    <Card id={`${id}-${promoName && promoName.trim()}`} elevation={0} className={classes.root}>
      <CardContent>
        <Typography gutterBottom variant="h5" component="h2">
          {promoName}
        </Typography>
        <Typography className={classes.paraPreWrap} variant="body2" color="textSecondary" component="p">
          {promoDescription}
        </Typography>
        <List
          dense
          subheader={<ListSubheader disableGutters>Условия</ListSubheader>}
          className={classes.root}
        >
          {promoConditions && promoConditions.map((cond, ind) => (
            <ListItem key={cond.Condition} disableGutters>
              <ListItemText id={`${cond.Condition}-${ind}`} primary={`${ind + 1}. ${cond.Condition}`} />
            </ListItem>
          ))}
        </List>
      </CardContent>
      <Divider />
    </Card>
  );
};

export default PromotionItem;
