import Layout from '../components/layout';
import SEO from '../components/seo';
import PromotionContainer from '../components/PromotionContainer/promotionContainer';
import strapiAPI from '../Utils/api/strapiApi';
import React, { useEffect, useState } from 'react';
import { captureException } from '@sentry/gatsby';

const PromotionsPage = ({ location }) => {
  const [promoData, setPromoData] = useState(null);

  useEffect(() => {
    (async () => {
      await strapiAPI.get('/promotions')
        .then((res) => {
          const promo = res.data;

          setPromoData(promo);
        })
        .catch((err) => {
          captureException(err);
        });
    })();
  }, []);

  return (
    <Layout location={location} showCategories>
      <SEO title="Акции" />
      <PromotionContainer promotions={promoData} />
    </Layout>
  );
};

export default PromotionsPage;
